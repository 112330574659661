<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <div>
     

      <b-row id="element-to-convert">



          <div class="scans-canvas"  >
            <scan-canvas v-if="scan" :scan="scan" />
       
          </div>

          <div class="scan-measures" v-if="measurements">
          <br /><br /> 
          Cantus Distance: {{ measurements.CantusDistance}}<br />
          Close Pupillary Distance: {{ measurements.PupillaryDistance}}<br />
          Far Pupillary Distance: {{ measurements.PupillaryDistance*1+4}}<br />
          Eye to lens distance (Topological): {{ measurements.EyeToLensDistance}}<br />
          Temple To Temple Width: {{ measurements.TempleDistance}}<br />
          Sphenoidal Width : {{ measurements.SpenoidalWidth}}<br />
          Eyebrow Height :  {{ measurements.EyeBrowHeight}}<br />
          Cheekbone Height : {{ measurements.CheekboneHeight}}<br />
          Left Auricular Height : {{ measurements.LeftAuricularHeight}}<br />
          Right Auricular Height : {{ measurements.RightAuricularHeight}}<br />
          Nose Crete Angle : {{ measurements.NoseCreteAngle}}<br />

          Right Nasal Caster Angle : {{ measurements.RightNasalCasterAngle}}<br />
          Left Nasal Caster Angle : {{ measurements.LeftNasalCasterAngle}}<br />
          Right Nasal Face Angle :  {{ measurements.RightNasalFaceAngle}}<br />
          Left Nasal Face Angle : {{ measurements.LeftNasalFaceAngle}}<br />

          Nasal Bone Width : {{ measurements.NasalWidth}}<br />
<!-- 
          Auricular Width : {{ measurements.AuricularWidth}} <br />
          
          
          
           
           
            
          
           
           Nasal height : {{ measurements.NasalHeight}}<br />
           
           
           
          
          
           
           
          Unprojected auricular width :  {{ measurements.UnprojectedAuricularWidth }} -->

          </div>
       
       
      </b-row>
 
      <br />  <br /> 
<center>
<b-button @click="generateReport()"
  
  variant="dark"
>
  PDF
</b-button>
</center> <br />  <br /> 
  
    </div>
  </b-overlay>
</template>

<script>

import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'
import ScanCanvas from './ScanCanvas.vue'
import ScanViewer from './ScanViewer.vue'
import html2pdf from "html2pdf.js";

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup,
    ScanCanvas,
    ScanViewer
  },
  data() {
    return {
      scan:null,
      selectedScan:localStorage.senseye_b2b_selected_scan,
      loading:true,
      measurements:null
    }
  },
  
  methods:{
   
    generateReport () {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 0,
        
        filename: "generated-pdf.pdf",
      });
    },
    async getScan() {
      let scan=await APIRequest.detail('scans',this.$route.params.id)
      this.scan={}
      this.scan=scan

      if(scan.measurements)
      {
        let measurements=scan.measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        measurements=measurements.replace('."',',"')
        
    
        this.measurements=JSON.parse(measurements)
      }
      
      console.log('TEST')

      console.log(this.measurements)
      //import data from "/Users/Documents/workspace/test.json"
    },
    selectScan(id) {
      this.selectedScan=this.scan.id
      localStorage.senseye_b2b_selected_scan=this.scan.id
   
    },
    
    
  },
  watch:{
    
  },
  async mounted()
  {
    if(this.$route.params.token)
    {
      localStorage.token_senseye_auth=this.$route.params.token
    }
    this.loading=true
    
    await this.getScan()
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.scans-canvas
{
  display:flex;
  width:100%;
  flex-direction: row;
  height:100%;
}

.overlay-scan {
  width:100%;
  height:100vh;
  z-index:999999;
}
#element-to-convert
{
  padding-left:20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
